import {React, useContext, useEffect} from 'react'
import Radio from '@mui/material/Radio';
import LuggageIcon from '@mui/icons-material/Luggage';
import GroupIcon from '@mui/icons-material/Group';
import { TransportArtContext } from '../../context/TransportArt';
import { DurationContext } from '../../context/DurationContext';
import { RabattcodeContext } from '../../context/RabattcodeContext';
import { LanguageContext } from "../../context/LanguageContext";
import { PriceContext } from '../../context/PriceContext';
import { DiscountData } from '../../source/discountData';


function CarListItem({car,distance,selectedCar,handleChange}) {

const { language } = useContext(LanguageContext);
const {transportArt} = useContext(TransportArtContext);
const {duration} = useContext(DurationContext);
const {rabattcode} = useContext(RabattcodeContext);
const {setPriceContext} = useContext(PriceContext);


let rabattCodeStatus = false;

let result1;
//let roundedResult1;
let formattedResult1;
// let roundedDistance = Math.round(distance * 100) / 100;
// duration muss durch 20 geteilt werden, da duration in 20km angegeben wird
let result2 = car.amount20km * (duration / 20);

if (distance < 100) {
    result1 = car.amount_under_100km * distance;
} else {
    result1 = car.amount_above_100km * distance;
}

let beforeRabattcodePrice1 = result1;
let beforeRabattcodePrice2 = result2;

if(rabattcode === DiscountData.discount[language].discountcode1_1 || rabattcode === DiscountData.discount[language].discountcode1_2){
    result1 = result1 * 0.9;
    result2 = result2 * 0.9;
    rabattCodeStatus = true;
}else if(rabattcode === DiscountData.discount[language].discountcode2_1 || rabattcode === DiscountData.discount[language].discountcode2_2){
    result1 = result1 * 0.85;
    result2 = result2 * 0.85;
    rabattCodeStatus = true;
}else if(rabattcode === DiscountData.discount[language].discountcode3_1 || rabattcode === DiscountData.discount[language].discountcode3_2){
    result1 = result1 * 0.8;
    result2 = result2 * 0.8;
    rabattCodeStatus = true;
}else{
    rabattCodeStatus = false;
}

// let RoundedbeforeRabattcodePrice1 = Math.ceil(beforeRabattcodePrice1 / 5) * 5;
// let RoundedbeforeRabattcodePrice2 = Math.ceil(beforeRabattcodePrice2 / 5) * 5;
let RoundedformattedResult1 = beforeRabattcodePrice1.toFixed(2);
let RoundedformattedResult2 = beforeRabattcodePrice2.toFixed(2);

if(RoundedformattedResult1 < 80){
    RoundedformattedResult1 = 80.00;
}

if(RoundedformattedResult2 < 80){
    RoundedformattedResult2 = 80.00;
}

// roundedResult1 = Math.ceil(result1 / 5) * 5;
formattedResult1 = result1.toFixed(2);

// const roundedResult2 = Math.ceil(result2 / 5) * 5;
const formattedResult2 = result2.toFixed(2);

if (formattedResult1 < 80) {
    formattedResult1 = 80.00;
}

if (formattedResult2 < 80) {
    formattedResult2 = 80.00;
}

useEffect(() => {
    if(selectedCar === car.name){
        const updatedPriceContext = {
            price1: formattedResult1,
            price2: formattedResult2
        };
        setPriceContext(updatedPriceContext);
    }
}, [selectedCar, formattedResult1, formattedResult2]);


  return (
    <div className="carlistitem-wrapper">
        <div className="carlistitem">
            <Radio
              checked={selectedCar === car.name}
              onChange={handleChange}
              value={car.name}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
            <div className="carlistitem-inner">
            <img alt={car.name} src={car.image}/>
            </div>
            <div className="carlistitem-texts">
                  
                <h2>{car[language].name}</h2>                    
                <p className="car-description">{car[language].desc}</p>
                <div className="carlistitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/> {car.seat}</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/> {car.luggage}</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/> {car.luggagesmall}</p>
                  </div>
                </div>
                {transportArt === 0 ? (
                      distance ? (
                        rabattCodeStatus ? (
                            <div className="rabatt-wrapper">
                                <h2 className="carlistitem-price rabatt-old">{RoundedformattedResult1} €</h2>
                                <h2 className="carlistitem-price rabatt-new">{formattedResult1} €</h2>
                            </div>
                        ) : (
                            <h2 className="carlistitem-price">{formattedResult1} €</h2>
                        )
                          
                      ) : (
                          <h2 className="carlistitem-price">0.00 €</h2>
                      )
                  ) : (
                      duration ? (
                        rabattCodeStatus ? (
                            <div className="rabatt-wrapper">
                                <h2 className="carlistitem-price rabatt-old" style={{textDecoration: 'line-through'}}>{RoundedformattedResult2} €</h2>
                                <h2 className="carlistitem-price rabatt-new">{formattedResult2} €</h2>
                            </div>
                        ) : (
                            <h2 className="carlistitem-price">{formattedResult2} €</h2>
                        )
                      ) : (
                          <h2 className="carlistitem-price">0.00 €</h2>
                      )
                  )}
            </div>
        </div>
    </div>
  )
}

export default CarListItem