import React, {useState, useEffect, useContext} from 'react';
import './buchung.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GoogleMapsSection from '../../components/booking/GoogleMapsSection';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import CarListOptions from '../../components/booking/CarListOptions';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { SourceContext } from '../../context/SourceContext';
import { DestinationContext } from '../../context/DestinationContext';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import InputItem from '../../components/booking/InputItem';
import { DateTimeContext } from '../../context/DateTimeContext';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DurationContext } from '../../context/DurationContext';
import { TransportArtContext } from '../../context/TransportArt';
import { SelectedCarContext } from '../../context/SelectedCarContext';
import { RabattcodeContext } from '../../context/RabattcodeContext';
import { DistanceContext } from '../../context/DistanceContext';
import { DurationForDistanceContext } from '../../context/DurationForDistance';
import { PriceContext } from '../../context/PriceContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import GoogleMapsSection2 from '../../components/booking/GoogleMapsSection2';
import { LanguageContext } from "../../context/LanguageContext";
import { TextData } from '../../source/textData';
import { MessagesData } from '../../source/messagesData';
import { DiscountData } from '../../source/discountData';
import axios from 'axios';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
    }

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
      
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Buchung() {

const handleChange = (event, newValue) => {
    setTransportArt(newValue);
};      

const { language } = useContext(LanguageContext);
const {transportArt, setTransportArt} = useContext(TransportArtContext);
const {datetime, setDateTime} = useContext(DateTimeContext);
const {source, setSource} = useContext(SourceContext);
const {destination, setDestination} = useContext(DestinationContext);
const {duration, setDuration} = useContext(DurationContext);
const {selectedCar, setSelectedCar} = useContext(SelectedCarContext);
const {rabattcode, setRabattcode} = useContext(RabattcodeContext);
const {distance, setDistance} = useContext(DistanceContext);
const {durationForDistance, setDurationForDistance} = useContext(DurationForDistanceContext);
const [payment,setPayment] = useState("Paypal");
const [rabattCodeStatus, setrabattCodeStatus] = useState(false);
const {priceContext, setPriceContext} = useContext(PriceContext);


useEffect(() => {
    if(rabattcode === DiscountData.discount[language].discountcode1_1 ||
       rabattcode === DiscountData.discount[language].discountcode1_2 ||
       rabattcode === DiscountData.discount[language].discountcode2_1 || 
       rabattcode === DiscountData.discount[language].discountcode2_2 ||
       rabattcode === DiscountData.discount[language].discountcode3_1 ||
       rabattcode === DiscountData.discount[language].discountcode3_2){
        setrabattCodeStatus(true);
    } else {
        setrabattCodeStatus(false);
    }
}, [rabattcode, language]);

const handlePayment = (event) => {
    setPayment(event.target.value);
};

const controlPropsPayment = (item) => ({
    checked: payment === item,
    onChange: handlePayment,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
});

const handleDuration = (event) => {
    setDuration(event.target.value);
};

const initialFormData = {
    abholort: '',
    zielort: '',
    datumzeit: '',
    dauer: '',
    distanz: '',
    dauer_std: '',
    vorname: '',
    nachname: '',
    email: '',
    telefon: '',
    flugnummer: '',
    rabattcode: '',
    message: '',
    transportArt: '',
    transportKlasse: '',
    zahlungsmethode: '',
    firmenname: '',
};

const [formData, setFormData] = useState(initialFormData);

  const handleSubmissionChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      abholort: source.name,
      zielort: destination.name,
      datumzeit: dayjs(datetime).format('DD. MMMM YYYY [um] HH:mm [Uhr]'),
      dauer: duration,
      transportArt: transportArt,
      transportKlasse: selectedCar,
      zahlungsmethode: payment,
      rabattcode: rabattcode,
      distanz: distance,
      dauer_std: durationForDistance,
      transportKlassePreis1: priceContext.price1,
      transportKlassePreis2: priceContext.price2,
    }));

  }, [source, destination, transportArt, selectedCar, duration, datetime, payment, rabattcode, distance, durationForDistance, priceContext]);

  //setSubmission ist dazu da, um die Success / Error Nachricht anzuzeigen
  const [submission, setSubmission] = useState(null);

  const handleSubmissionMessage = () => {
    if(formData.vorname === "" || formData.nachname === "" || formData.email === "" || formData.telefon === ""){
        setSubmission(null);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Validation for transportKlasse: 0
    if (transportArt === 0 && (!source || source === "" || !destination || destination === "")) {
       alert(MessagesData.buchung[language].source_and_destination_error);

       return; // Stop submission
    }   

    // Validation for transportKlasse: 1
    if (transportArt === 1 && (!source || source === "")) {
       alert(MessagesData.buchung[language].source_error);
       return; // Stop submission
    }
    
    // Validation for Firma
    if (rabattCodeStatus === true && formData.firmenname === "" && (rabattcode === DiscountData.discount[language].discountcode3_1 || rabattcode === DiscountData.discount[language].discountcode3_2) ) {
        alert(MessagesData.discount[language].company_error);
        return; // Stop submission
     }

    axios.post('https://buchung.vipdrivers.de/', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log('Form submitted successfully');
        setSubmission(true);
    })
    .catch(error => {
        setSubmission(false);
    });
};

const currentDate = dayjs().add(3, 'hour');
const minSelectableTime = datetime.isSame(currentDate, 'day') ? currentDate : datetime.startOf('day');

return (
      
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <div className="buchung">
      <NavbarWeb scrolled={true}/>
          <div className="buchungContainer">
              <Container maxWidth="lg">
                {transportArt === 0 ? <GoogleMapsSection /> : <GoogleMapsSection2 />}
                  <p>{transportArt === 0 ? `${TextData.buchung[language].map.desc1}: ${distance.toFixed(1)} km, ${TextData.buchung[language].map.desc2}: ${durationForDistance.toFixed(2)} ${TextData.buchung[language].map.desc3}` : "\u00A0"}</p>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ width: '100%'}}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
                              <Tabs value={transportArt} TabIndicatorProps={{ style: { display: 'none' }}} onChange={handleChange} aria-label="basic tabs example">
                                  <Tab label={TextData.buchung[language].formular.tab1} {...a11yProps(0)} style={{minWidth: '50%', fontSize:' 16px'}} sx={{
                                  borderBottom: transportArt === 0 ? '2px solid #1f2c34' : '2px solid transparent',
                                  '&:selected': { backgroundColor: 'transparent' },
                                  '&.Mui-selected': { color: 'black'}}}/>
                                  <Tab label={TextData.buchung[language].formular.tab2} {...a11yProps(1)} style={{minWidth: '50%', fontSize:' 16px'}} sx={{
                                  borderBottom: transportArt === 1 ? '2px solid #1f2c34' : '2px solid transparent',
                                  '&:selected': { backgroundColor: 'transparent' },
                                  '&.Mui-selected': { color: 'black'}}}/>
                              </Tabs>
                          </Box>
                 <CustomTabPanel value={transportArt} index={0}>
                    <Grid container spacing={1} sx={{padding: 0}}>
                        <Grid sx={{mb:2, zIndex:3}} item xs={12} md={6}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{pt: 0, boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                                <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input1}</label>
                                <InputItem type="source"/>
                            </Box>
                        </Grid>
                        <Grid sx={{mb:2, zIndex:2}} item xs={12} md={6}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{pt: 0, boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                                <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input2}</label>
                                <InputItem type="destination"/>
                            </Box>
                        </Grid>
                    </Grid>
                  </CustomTabPanel>
                                  
                  <CustomTabPanel value={transportArt} index={1}>
                    <Grid container spacing={1} sx={{padding: 0}}>
                        <Grid sx={{mb:2, zIndex:3}} item xs={12} md={6}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{pt: 0, boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                                <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input1}</label>
                                <InputItem type="source"/>
                            </Box>
                        </Grid>
                        <Grid  item xs={12} md={6} sx={{pt:2, mb:2}}>
                        <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{pt: 0, boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                            <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input3}</label>
                                <div className="mobiledatepicker-wrapper">
                                    <Select
                                        required
                                        MenuProps={{
                                        PaperProps: {
                                            style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                            },
                                        },
                                        }}
                                        value={duration}
                                        onChange={handleDuration}
                                        label="Dauer"
                                        fullWidth
                                        sx={{'& .MuiSelect-iconOutlined': {top: 'calc(50% - .5em)'},'& .MuiSelect-select':{padding: 0, paddingTop: '2px', paddingLeft: '3px'},'& .MuiOutlinedInput-notchedOutline': {padding:0, border: 'none' },}}
                                        >
                                        <MenuItem value={40}>{TextData.searchSection[language].dauer1}</MenuItem>
                                        <MenuItem value={60}>{TextData.searchSection[language].dauer2}</MenuItem>
                                        <MenuItem value={80}>{TextData.searchSection[language].dauer3}</MenuItem>
                                        <MenuItem value={100}>{TextData.searchSection[language].dauer4}</MenuItem>
                                        <MenuItem value={120}>{TextData.searchSection[language].dauer5}</MenuItem>
                                        <MenuItem value={140}>{TextData.searchSection[language].dauer6}</MenuItem>
                                        <MenuItem value={160}>{TextData.searchSection[language].dauer7}</MenuItem>
                                        <MenuItem value={180}>{TextData.searchSection[language].dauer8}</MenuItem>
                                        <MenuItem value={200}>{TextData.searchSection[language].dauer9}</MenuItem>
                                        <MenuItem value={220}>{TextData.searchSection[language].dauer10}</MenuItem>
                                        <MenuItem value={240}>{TextData.searchSection[language].dauer11}</MenuItem>
                                        <MenuItem value={260}>{TextData.searchSection[language].dauer12}</MenuItem>
                                        <MenuItem value={280}>{TextData.searchSection[language].dauer13}</MenuItem>
                                        <MenuItem value={300}>{TextData.searchSection[language].dauer14}</MenuItem>
                                        <MenuItem value={320}>{TextData.searchSection[language].dauer15}</MenuItem>
                                        <MenuItem value={340}>{TextData.searchSection[language].dauer16}</MenuItem>
                                        <MenuItem value={360}>{TextData.searchSection[language].dauer17}</MenuItem>
                                        <MenuItem value={380}>{TextData.searchSection[language].dauer18}</MenuItem>
                                        <MenuItem value={400}>{TextData.searchSection[language].dauer19}</MenuItem>
                                        <MenuItem value={420}>{TextData.searchSection[language].dauer20}</MenuItem>
                                        <MenuItem value={440}>{TextData.searchSection[language].dauer21}</MenuItem>
                                        <MenuItem value={460}>{TextData.searchSection[language].dauer22}</MenuItem>
                                        <MenuItem value={480}>{TextData.searchSection[language].dauer23}</MenuItem>
                                        <MenuItem value={960}>{TextData.searchSection[language].dauer24}</MenuItem>
                                        <MenuItem value={1440}>{TextData.searchSection[language].dauer25}</MenuItem>
                                        <MenuItem value={1920}>{TextData.searchSection[language].dauer26}</MenuItem>
                                        <MenuItem value={2400}>{TextData.searchSection[language].dauer27}</MenuItem>
                                    </Select>
                                </div>
                            </Box>
                            </Grid>       
                      </Grid>
                  </CustomTabPanel>
                  </Box>
                  <Grid container spacing={1} sx={{padding: 0}}>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                                <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input4}</label>
                                <div className="mobiledatepicker-wrapper">
                                <MobileDatePicker  orientation="portrait" className="gm-datepicker"
                                    format="DD.MM.YYYY"
                                    value={datetime}
                                    minDate={dayjs().add(3, 'hour')}
                                    onChange={(newValue) => setDateTime(newValue)}
                                    slotProps={{
                                        // Targets the `InputAdornment` component.
                                        openPickerButton: {
                                        color: 'primary',
                                        },
                                        inputAdornment: {
                                        position: 'start',
                                        },
                                    }}
                                    sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },}}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                            <label className="mobiledatepicker-label">{TextData.buchung[language].formular.input5}</label>
                                <div className="mobiledatepicker-wrapper">
                                <MobileTimePicker  orientation="portrait" className="gm-datepicker"
                                    value={datetime}
                                    ampm={false}
                                    minTime={minSelectableTime}
                                    onChange={(newValue) => setDateTime(newValue)}
                                    slotProps={{
                                        // Targets the `InputAdornment` component.
                                        openPickerButton: {
                                        color: 'primary',
                                        },
                                        inputAdornment: {
                                        position: 'start',
                                        },
                                    }}
                                    sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }}}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="vorname"
                                required                                
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input6}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="nachname"
                                required
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input7}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="email"
                                required
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input8}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="telefon"
                                required
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input9}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="firmenname"
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input10}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={6}>
                            <TextField
                                name="flugnummer"
                                onChange={handleSubmissionChange}
                                label={TextData.buchung[language].formular.input11}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={12}>
                            <TextField
                                name="rabattcode"
                                onChange={(event) => { 
                                    if (event && event.target) { 
                                        setRabattcode(event.target.value);
                                    }
                                }} 
                                value={rabattcode}
                                label={TextData.buchung[language].formular.input12}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                            {rabattcode && rabattcode.trim() !== "" && (
                                rabattCodeStatus ? (
                                    <p className="rabatt-success">{MessagesData.discount[language].success}</p> 
                                ) : (
                                    <p className="rabatt-error">{MessagesData.discount[language].error}</p>
                                )
                            )}
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={12}>
                        <p>{TextData.buchung[language].formular.desc1}</p>
                        <TextField
                                name="message"
                                onChange={handleSubmissionChange}
                                multiline
                                rows={4}
                                label={TextData.buchung[language].formular.input13}
                                fullWidth
                                sx={{ boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                />
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={12}>
                            <CarListOptions distance={distance}/>
                        </Grid>
                        <Grid sx={{mb:1}} item xs={12} md={12}>
                            <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", border: '1px solid lightgrey' , p: 2, width:'100%' }}>
                                <div>
                                    <p className="klassen-inhalte-titel">{TextData.buchung[language].transportmethod.description1}</p>
                                    <div className="klassen-inhalte"><CheckCircleOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description2}</p></div>
                                    <div className="klassen-inhalte"><CheckCircleOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description3}</p></div>
                                    <div className="klassen-inhalte"><CheckCircleOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description4}</p></div>

                                    <p></p>
                                    <p className="klassen-inhalte-titel">{TextData.buchung[language].transportmethod.description5}</p>   
                                    <div className="klassen-inhalte"><ErrorOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description6}</p></div>
                                    <div className="klassen-inhalte"><ErrorOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description7}</p></div>
                                    <div className="klassen-inhalte"><ErrorOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description8}</p></div>
                                    <div className="klassen-inhalte"><ErrorOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description9}</p></div>
                                    <div className="klassen-inhalte"><ErrorOutlineOutlinedIcon/><p>{TextData.buchung[language].transportmethod.description10}</p></div>

                                </div>
                            </Box>
                        </Grid>
                        <Grid sx={{mb:4}} item xs={12} md={12}>
                            <h2>{TextData.buchung[language].paymentmethod.label1}</h2>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                onChange={handlePayment}
                                value={payment}
                                required
                                >
                                <div className="paymentitem">
                                    <div className="payment-inner">
                                        <Radio value="Kreditkarte" {...controlPropsPayment("Kreditkarte")} />
                                        <img alt="payment_creditcard" src={TextData.buchung[language].paymentmethod.img2}/> 
                                    </div>
                                    <div className="payment-inner">
                                        <Radio value="Ueberweisung" {...controlPropsPayment("Ueberweisung")} />
                                        <img alt="payment_banktransfer" src={TextData.buchung[language].paymentmethod.img3}/> 
                                    </div>
                                </div>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                <FormControlLabel sx={{pt:2, '@media (max-width:1060px)': {alignItems: 'start'}}} control={<Checkbox sx={{'@media (max-width:1060px)': {paddingTop: '1px'}}} required/>} label={MessagesData.contact[language].dsgvo} />
                  <Button onClick={()=>{handleSubmissionMessage();}} type="submit" variant="contained" fullWidth sx={{
                      '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                      '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                      color: 'black', bgcolor: '#DBB47E', padding: '16px 32px', boxShadow: 'none', mt:4}}>
                      {TextData.buchung[language].button}
                  </Button>
                    {submission === true ? (
                      <p className="buchung-success">{MessagesData.contact[language].success}</p>
                    ) : submission === false ? (
                      <p className="buchung-error">{MessagesData.contact[language].error}</p>
                    ) : null}
                  </form>
              </Container>
          </div>
      <Footer/>
  </div>
  </LocalizationProvider>
)
}

export default Buchung